import React from 'react';
import styles from "./barGraph.module.scss";

const BarGraph = ({barWidth, barColor, barWrapperBackground, direction}) =>{

 

    return(
        <div className={`${styles.barChartWrapper} ${direction === 'left'? styles.toLeft : styles.toRight}`} 
             style={{backgroundColor: barWrapperBackground ? barWrapperBackground : 'transparent'}}>
            <div className={styles.progress} style={{width: barWidth + '%', backgroundColor: barColor ? barColor : '#405363'}}></div>
        </div>
    )
}

export default BarGraph;