import React, { useState } from "react";
import styles from  './select-dropdown.module.scss'; // import CSS file with animation styles

const SelectDropdown = ({ options, onSelect, defaultColumn }) => {
  const defaultItem = (defaultColumn) ? options.filter((item) =>
      item.value === defaultColumn)[0]
      : options[0];
  const [selectedOption, setSelectedOption] = useState(defaultItem);
  const [menuOpen, setMenuOpen] = useState(false);


  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setMenuOpen(false);
  };

  const handleMenuToggle = () =>{
    setMenuOpen(!menuOpen)
  }

  return (
    <div className={styles.dropdownWrapper}>
        <button 
            className={styles.dropdownToggle}
            onClick={handleMenuToggle}
        >
            {defaultItem.label || selectedOption.label}

            <svg width="12px" height="12px" viewBox="0 0 12 12">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-408.000000, -182.000000)" fill="#405363">
                        <g transform="translate(40.000000, 163.000000)">
                            <polygon transform="translate(374.000000, 25.000000) rotate(180.000000) translate(-374.000000, -25.000000) " points="374 19 380 31 368 31"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
        {menuOpen && (
        <ul className={styles.dropdownOptions}>
            {options.map((option) => (
            <li 
                key={option.value} 
                onClick={() => handleOptionSelect(option)}
                className={`${selectedOption.value === option.value ? styles.menuItemNone: ''}`}
            >
                {option.label}
            </li>
            ))}
        </ul>
        )}
    </div>
  );
};

export default SelectDropdown;
