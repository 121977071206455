const wealthRemainsOverview = [
    {
        "rank": 16,
        "country": "Spain",
        "millionaires": 106300,
        "centiMillionaires": 208,
        "billionaires": 22,
        "centiGrowth": 6
    },
    {
        "rank": 18,
        "country": "Brazil",
        "millionaires": 82400,
        "centiMillionaires": 210,
        "billionaires": 25,
        "centiGrowth": -28
    },
    {
        "rank": 23,
        "country": "Russian Federation",
        "millionaires": 68400,
        "centiMillionaires": 409,
        "billionaires": 43,
        "centiGrowth": -24
    },
    {
        "rank": 24,
        "country": "Portugal",
        "millionaires": 67200,
        "centiMillionaires": 108,
        "billionaires": 5,
        "centiGrowth": 32
    },
    {
        "rank": 25,
        "country": "Greece",
        "millionaires": 64700,
        "centiMillionaires": 105,
        "billionaires": 8,
        "centiGrowth": 14
    },
    {
        "rank": 26,
        "country": "Taiwan (Chinese Taipei)",
        "millionaires": 62600,
        "centiMillionaires": 135,
        "billionaires": 23,
        "centiGrowth": 42
    },
    {
        "rank": 29,
        "country": "Saudi Arabia",
        "millionaires": 58300,
        "centiMillionaires": 195,
        "billionaires": 22,
        "centiGrowth": 35
    },
    {
        "rank": 30,
        "country": "New Zealand",
        "millionaires": 56100,
        "centiMillionaires": 110,
        "billionaires": 8,
        "centiGrowth": 48
    },
    {
        "rank": 38,
        "country": "South Africa",
        "millionaires": 37400,
        "centiMillionaires": 102,
        "billionaires": 5,
        "centiGrowth": -20
    },
    {
        "rank": ">50",
        "country": "Vietnam",
        "millionaires": 19400,
        "centiMillionaires": 58,
        "billionaires": 6,
        "centiGrowth": 98
    },
    {
        "rank": ">50",
        "country": "Monaco",
        "millionaires": 15600,
        "centiMillionaires": 185,
        "billionaires": 25,
        "centiGrowth": 68
    },
    {
        "rank": ">50",
        "country": "Malta",
        "millionaires": 10200,
        "centiMillionaires": 45,
        "billionaires": 3,
        "centiGrowth": 74
    },
    {
        "rank": ">50",
        "country": "Nigeria",
        "millionaires": 8200,
        "centiMillionaires": 23,
        "billionaires": 3,
        "centiGrowth": -45
    },
    {
        "rank": ">50",
        "country": "Mauritius",
        "millionaires": 5100,
        "centiMillionaires": 15,
        "billionaires": " -   ",
        "centiGrowth": 87
    }
];

export {wealthRemainsOverview};