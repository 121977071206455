import React from 'react';
import { henleyFlagLink } from '../../../hooks/utils';
import { countryCode } from '../../../data/data-country-code';
import  styles from  './country-flag.module.scss'; // import CSS file with animation styles

const  CountryFlag= ({country}) => {
    const imageURL = henleyFlagLink(countryCode[country].ISO2);
    
    return (
      <span className={styles.countryFlag}>
          <img src={imageURL} alt={country} /> 
          <span className={styles.countryName}>{country}</span>
      </span>
    )

}

export default CountryFlag;
