// export const DATA_SHEET_ID =
//     process.env.REACT_APP_DATA_SHEET || "1yugNuvzzC5uw4l1GO1MK0bxd8wojVJgVknRQjw8L8vw"; // dev data
//     // process.env.REACT_APP_DATA_SHEET || "1G-pAFxYuOujnA0BnuNMmKD9qRK9L_YWXnffmXuDyq7Y"; // prod data

// export const SHEET_SERVER_URL = `https://holy-sheet.visualise.today`;

// export const STATIC_DATA_BASE_URL =
//     process.env.REACT_APP_DATA_BASE_URL || process.env.PUBLIC_URL + "/data";

// export const DATA_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=data`
// export const SCORES_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=score_columns`

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || "pk.eyJ1Ijoic21hbGxtdWx0aXBsZXMiLCJhIjoiRk4xSUp6OCJ9.GilBdBaV0oKMZgBwBqRMWA";
if (!MAPBOX_TOKEN) {
    console.error("Please provide 'REACT_APP_MAPBOX_TOKEN' environment variable!");
}

export const MAPBOX_BASEMAP_STYLE_ID =
    process.env.REACT_APP_BASEMAP_ID ||
    "mapbox://styles/smallmultiples/cksbrvf0b4wqh18s39qy6mzs8";

export const PRIVATE_WEALTH_DATA = 'private_wealth_data.json';
export const WEALTH_MIGRATION_DATA = 'wealth_migration_data.json';
