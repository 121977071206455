import React, {useState, useEffect, Fragment, useRef} from 'react';
import styles from './country-inflow.module.scss';
import {inflowTop10} from "../../data/data-top10-inflow";
import { formatNumberWithDot, findHighestValue } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import ProgressBar from '../common/progressBar/progress-bar';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';
import WealthFlowDetail from "../common/popup/WealthFlowDetail";

const CountryInflow = () => {

    const [sortColumn, setSortColumn] = useState("inflowVal");
    const [countryCount, setCountryCount] = useState(1);
    const [wealthGrowthCount, setWealthGrowthCount] = useState(1);
    const [detailCard, setDetailCard] = useState('');
    const [showCard, setShowCard] = useState(false);
    // const popUpRef = useRef(null)


    const maxForecastValue = findHighestValue(inflowTop10, 'inflowVal');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [marginTopRow, setMarginTopRow] = useState(0);
    const [marginBottomRow, setMarginBottomRow] = useState(0);

    const numOfCountries = inflowTop10.length;


    useEffect(() => {
        const handleResize = () => {
            // Check if the width has actually changed and it's not just a mobile browser adjusting the height
            if (window.innerWidth !== windowWidth) {
                setWindowWidth(window.innerWidth); // Update the width

                // if (window.innerWidth < 799) {
                //   setSortColumn('wealthPerCapita');
                // } else {
                //   // Set to other value or keep the current value
                //   // setSelection('otherValue');
                // }
            }
        };

        // Set the initial state based on current window size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]); // Dependency on windowWidth to update when it changes


    const handleClickRow = (data, index) =>{
        // popUpRef.current.scrollIntoView({ behavior: "smooth" });

        if (detailCard.country === data.country && showCard) {
            setShowCard(false);
            return;
        }
        setDetailCard(data);
        setShowCard(true);
        const topWidth = (windowWidth < 800) ? (numOfCountries - index - 1) * 50 : 0;
        const setter = (numOfCountries - index - 9);
        const bottomWidth = (windowWidth < 800 && setter > 0) ? (setter) * 50 + 70: 0;
        setMarginTopRow(topWidth);
        setMarginBottomRow(bottomWidth);

    }

    const cardClose = () =>{
        setShowCard(false);
    }

    const handleSort = (columnName) => {
        switch (columnName) {
            case 'country':
                (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
                break;
            case 'inflowVal':
                (wealthGrowthCount === 1) ? setWealthGrowthCount(-1) : setWealthGrowthCount(1);
                break;
            default:
                break;
        }

        setSortColumn(columnName);
        // setDisplay(-1);

    };

    const reverseSortNum = (columnName, count) => {
        if (count === 1) {
            return inflowTop10.sort((a, b) => b[columnName] - a[columnName]);
        }
        return inflowTop10.sort((a, b) => a[columnName] - b[columnName]);
    }

    const handleSortingOptions = (columnName) => {
        switch (columnName) {
            case 'country':
                return (countryCount === 1) ?
                    inflowTop10.sort((a, b) => b[columnName].localeCompare(a[columnName]))
                    : inflowTop10.sort((a, b) => a[columnName].localeCompare(b[columnName]));
            case 'inflowVal':
                return reverseSortNum(columnName, wealthGrowthCount);
            default:
                break;
        }
    }


    const sortedData = sortColumn
        ? handleSortingOptions(sortColumn)
        : inflowTop10;


    return (
        <>
            {/*<div className="row">*/}
            {/*    <div className="col-md-4 col-lg-3">*/}
            {/*        <h3>Click on the chart below to see country profiles</h3>*/}
            {/*    </div>*/}
            {/*    <div className="col-md-8 col-lg-9">*/}
            {/*        <p>The inflows data represents the Top 10 countries globally in terms of net inflows of millionaires projected for 2024 (namely, the difference between the number of HNWIs who relocate to and the number who emigrate from each country). Nine of the Top 10 countries for net millionaire inflows in 2024 host*/}
            {/*            formal <a className="text-underline"*/}
            {/*                      href="https://www.henleyglobal.com/countries"*/}
            {/*                      target="_blank"*/}
            {/*                      rel="noreferrer">investment*/}
            {/*                migration</a> programs and actively encourage foreign direct investment in return for <a*/}
            {/*                className="text-underline" href="https://www.henleyglobal.com/residence-investment"*/}
            {/*                target="_blank" rel="noreferrer">residence</a> or <a className="text-underline"*/}
            {/*               href="https://www.henleyglobal.com/citizenship-investment"*/}
            {/*               target="_blank"*/}
            {/*               rel="noreferrer">citizenship</a> rights.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* <h3 >Projected net inflows of HNWIs in 2024</h3>*/}

            {/*<div>*/}
            {/*  <div ref={popUpRef} className={styles.positionToScroll}></div>*/}
            {/*  {showCard ? <WealthFlowDetail item={detailCard} onClose={cardClose}/> : null}*/}
            {/*</div>*/}

            <table className={`chartTable ${styles.overviewChartTable}`}>
                <thead>
                <tr>
                    <th
                        className={`text-left ${styles.cellHideLarge} ${styles.countryColumn} `}
                        style={{ 'paddingLeft': '10px' }}
                    >
                        <div className={styles.sortColumnTitle}>
                            <span className={styles.sortColumnTitleText}>Country</span>
                        </div>
                    </th>
                    <th
                        className={`text-left ${styles.cellHideMobile} ${styles.countryColumn}  ${sortColumn === 'country' ? styles.selectedColumn : ''}`}
                        // onClick={() => handleSort("country")}
                    >
                        <div className={styles.sortColumnTitle}>

                            <span className={styles.sortColumnTitleText}>Country</span>
                            {/*{(countryCount === 1) ? <IconSort /> : <ReverseIconSort />}*/}
                        </div>
                    </th>

                    <th
                        className={
                            `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.progressBarColumn}
                              ${sortColumn === 'inflowVal' ? styles.selectedColumn : ''}
                              ${sortColumn === 'inflowVal' ? styles.displayColumnTitleDiv : ''}
              `}
                        onClick={() => handleSort("inflowVal")}
                    >
                        <div className={styles.sortColumnTitle}>
                <span
                    className={styles.sortColumnTitleText}>Projected net inflows of millionaires in 2024</span>
                            {(wealthGrowthCount === 1) ? <IconSort/> : <ReverseIconSort/>}
                        </div>
                    </th>

                </tr>
                </thead>

                <tbody>
                {/* {sortedData.slice(0, numOfCitiesShow).map((item, index) => ( */}
                {sortedData.map((item, index) => (
                    <Fragment key={index}>
                        {/*<div ref={popUpRef} className={styles.positionToScroll}></div>*/}

                        <tr className={`${styles.contentRow}`}
                            key={item.country}
                            onClick={() => handleClickRow(item, index)}
                        >
                            <td className={`text-left 
                                ${styles.sortableColumn} 
                                ${sortColumn === 'country' ? styles.selectedColumn : ''}
                              `}
                                style={{'paddingLeft': '10px'}}
                            >
                                <CountryFlag country={item.country}/>
                            </td>
                            <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'inflowVal' ? styles.percentColumn : ''}
                                ${sortColumn === 'inflowVal' ? styles.selectedColumn : ''}
                              `}
                            >
                                <ProgressBar
                                    percentValue={item.inflowVal > 0 ? item.inflowVal / maxForecastValue * 100 : 0}
                                    labelText={item.inflowVal > 0 ? "+" + formatNumberWithDot(item.inflowVal) : "- " + formatNumberWithDot(Math.abs(item.inflowVal))}
                                    barHeight='30px'
                                />
                            </td>

                        </tr>
                    </Fragment>
                ))}
                </tbody>
            </table>

            <div className={styles.popupPosition}>
                {/*<div ref={popUpRef} className={styles.positionToScroll}></div>*/}
                {showCard ?
                    <div className={styles.safariMarginTopFix}
                        style={{ 'marginTop': `-${marginTopRow}px`, 'marginBottom': `${marginBottomRow}px`}}>
                        <WealthFlowDetail item={detailCard} onClose={cardClose}/>
                    </div>
                    : null}
            </div>

            <div className="footer-note mt-5">
                <ul>
                    <li>These are provisional figures for the full year 2024. They are based on year-to-date HNWI movements to
                        June 2024. Figures are rounded to the nearest 100.
                    </li>
                    <li>‘Millionaires’ and ‘HNWIs’ refer to individuals with liquid investable wealth of USD 1 million or
                        more.
                    </li>
                </ul>
                <p>Source: New World Wealth</p>
            </div>
        </>
    )
}

export default CountryInflow;