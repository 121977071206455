import React, { useState, useEffect } from 'react';
import useData from '../../hooks/useData';
import styles from "./migration.module.scss";
import BarGraph from '../common/graph/BarGraph';
import { formatNumberWithDot } from '../../hooks/utils';
import { dataConvertOverview } from '../../utils/overall-convert';
import { top15Overview } from '../../data/data-top15-overview';

const MigrationGraph = () =>{

    let { wealthMigrationData } = useData();
    const [worldMigrationsData, setWorldMigrationsData] = useState([]);
    const [maxValue, setMaxValue] = useState();

    const datatop50Overview = () => {
        return dataConvertOverview(top15Overview);
    }

    useEffect(() => {
        if (wealthMigrationData) {
            const data = wealthMigrationData.worldMigrations;

            const dataMax = wealthMigrationData.worldMigrations.reduce((a, b) => a = a > b.HNWIsMigrated ? a : b.HNWIsMigrated, 0);

            setWorldMigrationsData(data);
            setMaxValue(dataMax);
        }
    }, [wealthMigrationData]);


    const renderTableRow = (item) => {

        let barWidth = Math.round(item.HNWIsMigrated / maxValue * 100);
        // let comparisonValue = item.comparisonLastYearNumber;
        // let comparisonPercent = item.comparisonLastYearPercent;
        let barColor = '';
        let borderDashed = false;

        // if(comparisonValue < 0){
        //     textColor = '#D86F6F';
        // }

        if (item.year === '2025') {
            barColor = '#C5C6C8';
        } else if(item.year === '2024'){
            barColor = '#6F7072';
        } else if(item.year === '2023'){
            borderDashed = true;
        } else {
            barColor = '#445261';
        }

        return(
            <tr key={item.year} className={borderDashed ? styles.borderBottomDashed : ''}>
                <td className={styles.yearColumn}>{item.year}<span className={styles.notes}>{item.notes}</span></td>
                <td className={styles.barChartColumn}>
                    <BarGraph barWidth={barWidth} barColor={barColor} />
                </td>
                {/* <td className="text-right" style={{'color': textColor}}>{comparisonValue !== 0 ? formatNumber(comparisonValue) : ''}</td>
                <td className="text-right" style={{'color': textColor}}>{comparisonPercent !== 0 ? formatPercentage(comparisonPercent) : ''}</td> */}
                <td className={`text-right ${styles.valueColumn}`}>{formatNumberWithDot(item.HNWIsMigrated)}</td>
            </tr>
        );
    }


    return(
        <div className="appContainer">
            <div className="container-fluid">
                {/*<div className="row mb-4">*/}
                {/*    <div className="col-md-7">*/}
                {/*        <p>The high-net-worth-individual migration data reflects the number of millionaires who have moved to a new country year on year since 2013 — namely, those who have relocated and remained in a new country for longer than six months. As the chart reveals, there was a steady growth trajectory until 2020 when the Covid-19 pandemic prevented tracking millionaire migration. The 2022 and 2023 projections predict a rapid recovery.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <table className={`${styles.table} ${styles.migrationGraphTable}`}>
                    <thead>
                    <tr>
                        <th className={styles.citynameColumn}>Year</th>
                        <th></th>
                        {/* <th colSpan={2} className="text-right">Comparison vs previous year</th> */}
                        <th className="text-right">No. of millionaires that migrated</th>
                    </tr>
                    </thead>
                    <tbody>
                    {worldMigrationsData &&
                        worldMigrationsData.map((item) =>
                            renderTableRow(item)
                        )}
                    </tbody>
                </table>

                <div className="footer-note mt-5">
                    <ul>
                        <li>The 2024 provisional figures are based on year-to-date HNWI movements to June 2024.</li>
                        <li>Figures rounded to the nearest 1,000.</li>
                        <li>‘Millionaires’ or ‘HNWIs’ refer to individuals with liquid investable wealth of USD 1 million or more.</li>
                    </ul>
                    <p>Source: New World Wealth</p>
                </div>
            </div>

        </div>
    );
}

export default MigrationGraph;