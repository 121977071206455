const watchlistData = [
    {
        "country": "Saudi Arabia",
        "inflowVal": 300,
        "notes": "The Saudi cities of Riyadh and Jeddah are increasingly popular hotspots for migrating millionaires, especially from North Africa and the Middle East. In our view, these two cities have the potential to mimic Dubai and Abu Dhabi in attracting large numbers of wealthy expats in the future.",
        "url": ""
    },
    {
        "country": "Malta",
        "inflowVal": 250,
        "notes": "One of the big success stories of the past decade, not just in terms of millionaire migration, but also in terms of overall wealth growth, Malta is currently the fastest growing wealth hub in Europe, with millionaire growth of +74% recorded over the past decade.",
        "url": "https://www.henleyglobal.com/residence-investment/malta"
    },
    {
        "country": "Mauritius",
        "inflowVal": 250,
        "notes": "An increasingly popular destination for relocating millionaires from South Africa, the UK, and France, Mauritius is currently the fastest growing wealth market in Africa and the third-fastest growing worldwide, with millionaire growth of +87% recorded over the past decade.",
        "url": "https://www.henleyglobal.com/residence-investment/mauritius"
    },
    {
        "country": "Monaco",
        "inflowVal": 200,
        "notes": "A significant number of centi-millionaires from the UK have been moving to Monaco over the past five years and we expect this number to accelerate in 2024/2025 following the scrapping of the UK non-dom tax status. Monaco is currently the third-fastest growing wealth hub in Europe (after Malta and Poland), with millionaire growth of +68% recorded over the past decade.",
        "url": "https://www.henleyglobal.com/residence-investment/monaco"
    },
    {
        "country": "Spain",
        "inflowVal": 200,
        "notes": "Recently introduced wealth taxes and Spain’s high estate duty are both major deterrents to incoming HNWIs — if not for these factors, millionaire inflows would probably be much higher. Popular hotspots traditionally include beachfront areas such as Malaga and Marbella.",
        "url": "https://www.henleyglobal.com/residence-investment/spain"
    },
    {
        "country": "France",
        "inflowVal": 200,
        "notes": "France’s millionaire inflows are undoubtedly constrained by the country’s tax rates, which are among the highest in Europe. Notwithstanding this, France is still expected to see a sizeable influx of millionaires in 2024, driven mainly by HNWI migration from London to Paris. Affluent cities and towns such as Nice, Cannes, Antibes, Saint Tropez, Èze, Saint-Jean-Cap-Ferrat, and Villefranche-sur-Mer also remain popular destinations.",
        "url": ""
    },
    {
        "country": "New Zealand",
        "inflowVal": 200,
        "notes": "Arguably the world’s safest country, New Zealand is known for its beautiful scenery and high standard of living. However, it should be noted that millionaire inflows into New Zealand for 2024 are well below the peak levels recorded in the 2010s when the country consistently attracted over +500 HNWIs per annum.",
        "url": "https://www.henleyglobal.com/residence-investment/new-zealand"
    }
];


export {watchlistData};