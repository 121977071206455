import React, {useState, Fragment, useEffect} from 'react';
import styles from './wealth-remains.module.scss';
import { dataConvertOverview } from '../../utils/overall-convert';
import { wealthRemainsOverview} from "../../data/data-wealth-remains";
import { formatNumberWithDot, findHighestValue } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import ProgressBar from '../common/progressBarNeg/progress-bar-neg.module';
import SelectDropdown from '../common/selectDropdown/select-dropdown';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';
import {rankSuffix} from "../../hooks/utils";
import wealthRemains from "./wealth-remains";

const title1 = "Millionaires (USD 1m+)";
const title2 = "Centi-millionaires (USD 100m+)";
const title3 = "Billionaires (USD 1bn+)";
const title4 = "Millionaire growth % 2013 to 2023";
// const title5 = "Wealth rank";

const options = [
  // { label: title5, value: "rank" },
  { label: title1, value: "millionaires" },
  { label: title2, value: "centiMillionaires" },
  { label: title3, value: "billionaires" },
  { label: title4, value: "centiGrowth" },
];

const WealthRemains = () => {
  const defaultColumn = "country";
  const [sortColumn, setSortColumn] = useState(defaultColumn);
  // const [display, setDisplay] = useState();
  const [countryCount, setCountryCount] = useState(0);
  // const [rankCount, setRankCount] = useState(1);
  const [centiMilCount, setCentiMilCount] = useState(1);
  const [milCount, setMilCount] = useState(1);
  const [bilCount, setBilCount] = useState(1);
  const [centiGrowthCount, setCentiGrowthCount] = useState(1);
  const [numOfCitiesShow, setNumOfCitiesShow] = useState(10);
  const [hideList, setHideList] = useState(true);


  const dataWealthRemainsOverview = () => {
    return dataConvertOverview(wealthRemainsOverview);
  }

    useEffect(() => {
        if (window.innerWidth < 800 && sortColumn === 'country') {
            setSortColumn('millionaires');
        }
    }, [sortColumn]);

  const maxGrowthValue = findHighestValue(wealthRemainsOverview, 'centiGrowth');

  const handleOptionSelect = (option) => {
    // setSelectedOption(option);
    setSortColumn(option.value);
    // setDisplay(-1);
  };
  
  const addNewLine = (str, splitW) => {
    const splited = str.split(splitW);
    splited[0] += '\n';
    return splited.join(splitW);
  }

  const handleSort = (columnName) => {
    switch (columnName) {
      case 'country':
        (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
        break;
      case 'millionaires':
        (milCount === 1) ? setMilCount(-1) : setMilCount(1);
        break;
      // case 'rank':
      //   (rankCount === 1) ? setRankCount(-1) : setRankCount(1);
      //   break;
      case 'centiMillionaires':
        (centiMilCount === 1) ? setCentiMilCount(-1) : setCentiMilCount(1);
        break;
      case 'billionaires':
        (bilCount === 1) ? setBilCount(-1) : setBilCount(1);
        break;
      case 'centiGrowth':
        (centiGrowthCount === 1) ? setCentiGrowthCount(-1) : setCentiGrowthCount(1);
        break;
      default:
        break;
    }

    setSortColumn(columnName);
    // setDisplay(-1);

  };

  const reverseSortNum = (columnName, count) => {
    if (count === 1) {
      return dataWealthRemainsOverview().sort((a, b) => b[columnName] - a[columnName]);
    }
    return dataWealthRemainsOverview().sort((a, b) => a[columnName] - b[columnName]);
  }

  const handleSortingOptions = (columnName) => {
    switch (columnName) {
      case 'country':
        return (countryCount === 1) ?
          dataWealthRemainsOverview().sort((a, b) => b[columnName].localeCompare(a[columnName]))
          : dataWealthRemainsOverview().sort((a, b) => a[columnName].localeCompare(b[columnName]));
      case 'millionaires':
        return reverseSortNum(columnName, milCount);
      // case 'rank':
      //   return reverseSortNum('millionaires', rankCount);
      case 'centiMillionaires':
        return reverseSortNum(columnName, centiMilCount);
      case 'billionaires':
        return reverseSortNum(columnName, bilCount);
      case 'centiGrowth':
        return reverseSortNum(columnName, centiGrowthCount);
      default:
        break;
    }
  }


  const sortedData = sortColumn
    // ? handleSortingOptions(sortColumn)
    ? handleSortingOptions('country')
    : dataWealthRemainsOverview();

  // const handleMobileRowClick = (index) => {
  //   setDisplay(index);
  // }

  const handleShowHideList = () => {
    setHideList(!hideList);
    setNumOfCitiesShow(numOfCitiesShow === 10 ? `${sortedData.length}` : 10);
  };


  return (
    <>
      {/* <h3 className="mb-5">TOP 50 CITIES FOR MILLIONAIRES</h3> */}
      <div className={`d-md-none ${styles.sortMobileWrapper}`}>
        <div className={styles.sortTitle}>Show by</div>
        <SelectDropdown options={options} onSelect={handleOptionSelect} defaultColumn={(sortColumn !== 'country') ? sortColumn : 'millionaires'}/>
      </div>
      <table className={`chartTable ${styles.overviewChartTable}`} >
        <thead>
        <tr>
            {/*<th*/}
            {/*    className={*/}
            {/*        `text-left */}
            {/*          ${styles.cellHideMobile}*/}
            {/*          ${styles.sortableColumn} */}
            {/*          ${sortColumn === 'rank' ? styles.selectedColumn : ''}*/}
            {/*  `}*/}
            {/*    width={8}*/}
            {/*    onClick={() => handleSort("rank")}*/}
            {/*>*/}
            {/*    <div className={styles.sortColumnTitle} >*/}
            {/*        {(rankCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
            {/*        <span className={styles.sortColumnTitleText}*/}
            {/*              style={{whiteSpace: 'pre-line'}}>{title5}</span>*/}
            {/*    </div>*/}
            {/*</th>*/}

            <th
                className={`text-left ${styles.cityColumn} ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''} ${sortColumn === 'centiGrowth' ? styles.mobileWidth : ''} `}
                // onClick={() => handleSort("country")}
                style={{ 'paddingLeft': '10px' }}
            >
                <div className={styles.sortColumnTitle}>
                    {/*<div className={styles.cellHideMobile}>{(countryCount === 1) ? <IconSort/> : <ReverseIconSort/>}</div>*/}
                    <span className={styles.sortColumnTitleText}>Country/Territory</span>
                </div>
            </th>

            {sortColumn !== "centiGrowth" && sortColumn === "country" ?
                <th
                    // rowSpan={2}
                    className={`text-right 
                                  ${styles.hiddenTableRow} 
                                  ${styles.sortableColumn} 
                                  ${sortColumn ? styles.selectedColumn : ''} 
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                `}
                    onClick={() => handleSort("millionaires")}
                >
                    <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title1, "(")}</span>
                        {/* <span className={styles.sortColumnTitleText}>{addNewLine(title1, "(")} Millionaires<br />(USD 1m+)</span> */}
                        {/*{(milCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
                    </div>
                </th>
                : ''
            }

            {/*<th*/}
            {/*    className={`text-right */}
            {/*                      ${styles.sortableColumn} */}
            {/*                      ${styles.hiddenTableRow} */}
            {/*                      ${sortColumn !== 'rank' ? styles.cellHideMobile : ''}*/}
            {/*                      ${sortColumn === 'rank' ? styles.selectedColumn : ''}*/}
            {/*                    `}*/}
            {/*    onClick={() => handleSort("rank")}*/}
            {/*>*/}
            {/*    <div className={styles.sortColumnTitle}>*/}
            {/*        <span className={styles.sortColumnTitleText}*/}
            {/*              style={{whiteSpace: 'pre-line'}}>{title5}</span>*/}
            {/*        /!* <span className={styles.sortColumnTitleText}>{addNewLine(title1, "(")} Millionaires<br />(USD 1m+)</span> *!/*/}
            {/*        {(rankCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
            {/*    </div>*/}
            {/*</th>*/}

            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.dataColumn}
                              ${sortColumn === 'millionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'millionaires' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("millionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title1, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>{addNewLine(title1, "(")} Millionaires<br />(USD 1m+)</span> */}
                    {/*{(milCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
                </div>
            </th>
            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.dataColumn}
                              ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'centiMillionaires' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("centiMillionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title2, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Centi-millionaires<br />(USD 100m+)</span> */}
                    {/*{(centiMilCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
                </div>
            </th>
            <th
                className={
                    `text-right   
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn}  
                              ${styles.dataColumn}
                              ${sortColumn === 'billionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'billionaires' ? styles.displayColumnTitleDiv : ''}

              `}
                onClick={() => handleSort("billionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title3, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Billionaires<br />(USD 1bn+)</span> */}
                    {/*{(bilCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}

                </div>
            </th>
            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.progressBarColumn}
                              ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              ${sortColumn === 'centiGrowth' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("centiGrowth")}
                colSpan="2"
                style={{width: '100%'}}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title4, "2013")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Millionaire growth %<br />(2013 to 2023)</span> */}
                    {/*{(centiGrowthCount === 1) ? <IconSort/> : <ReverseIconSort/>}*/}
                </div>
            </th>


            {/* <th className={`text-right ${styles.cellHideMobile}`}>Investment <br/>migration options</th> */}
        </tr>
        </thead>
          <tbody>
          {sortedData.map((item, index) => (
              <Fragment key={index}>
                  <tr className={`${styles.contentRow} ${styles.openedRow}`}>
                      {/* <tr onClick={() => handleMobileRowClick(index)} className={`${styles.contentRow} ${index === display ? styles.openedRow : ''}`}> */}
                      {/*<td className={`text-left */}
                      {/*          ${styles.cellHideMobile} */}
                      {/*          ${styles.sortableColumn} */}
                      {/*          ${sortColumn === 'rank' ? styles.selectedColumn : ''}*/}
                      {/*        `}*/}
                      {/*  style={{ 'paddingLeft': '10px' }}*/}
                      {/*>{rankSuffix(item.rank)}</td>*/}
                      <td className={`text-left 
                                ${styles.sortableColumn} 
                                ${sortColumn === 'country' ? styles.selectedColumn : ''}
                              `}
                          style={{ 'paddingLeft': '10px' }}
                      >
                        <CountryFlag country={item.country}/>
                      </td>

                      {sortColumn !== "centiGrowth" && sortColumn !== "country" ?
                          <td
                              // rowSpan={2}
                              className={`text-right 
                                  ${styles.hiddenTableRow} 
                                  ${styles.sortableColumn} 
                                  ${styles.combinateRowCells} 
                                  ${sortColumn ? styles.selectedColumn : ''} 
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                `}>
                              {sortColumn !== "rank"  ?
                              formatNumberWithDot(item[sortColumn]) : rankSuffix(item[sortColumn]) }
                          </td>
                          : ''
                      }

                      {sortColumn !== 'centiGrowth' && sortColumn === "country" ?
                          <td
                              // rowSpan={2}
                              className={`text-right 
                                  ${styles.hiddenTableRow} 
                                  ${styles.sortableColumn} 
                                  ${styles.combinateRowCells} 
                                  ${sortColumn ? styles.selectedColumn : ''} 
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                `}>
                              {formatNumberWithDot(item['millionaires'])  }
                          </td>
                          : ''
                      }

                      {sortColumn === 'centiGrowth' ?
                          <td
                              // rowSpan={2}
                              className={`text-right 
                                  ${styles.sortableColumn} 
                                  ${styles.hiddenTableRow} 
                                  ${sortColumn !== 'centiGrowth' ? styles.cellHideMobile : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                                `}
                              style={{paddingRight: 0, width: '5%'}}
                          >
                              <ProgressBar
                                  percentValue={item.centiGrowth < 0 ? Math.abs(item.centiGrowth) : 0}
                                  labelText={''}
                                  type="left"
                              />
                          </td>
                          : ''
                      }

                      {sortColumn === 'centiGrowth' ?
                          <td
                              // rowSpan={2}
                              className={`text-right 
                                  ${styles.sortableColumn} 
                                  ${styles.hiddenTableRow} 
                                  ${sortColumn !== 'centiGrowth' ? styles.cellHideMobile : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                                `}
                              style={{paddingLeft: 0, borderRight: 0, width: '25%'}}
                          >
                              <ProgressBar
                                  percentValue={item.centiGrowth > 0 ? item.centiGrowth / maxGrowthValue * 100 : 0}
                                  labelText={item.centiGrowth + "%"}
                              />
                          </td>
                          : ''
                      }


                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'millionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.millionaires)}
                      </td>
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.centiMillionaires)}
                      </td>
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'billionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.billionaires)}
                      </td>


                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              `}
                          style={{paddingRight: 0, width: '5%'}}
                      >
                          <ProgressBar
                              percentValue={item.centiGrowth < 0 ? Math.abs(item.centiGrowth) : 0}
                              labelText={''}
                              type="left"
                          />
                      </td>
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              `}
                          style={{paddingLeft: 0, width: '25%'}}
                      >
                          <ProgressBar
                              percentValue={item.centiGrowth > 0 ? item.centiGrowth / maxGrowthValue * 100 : 0}
                              labelText={item.centiGrowth + "%"}
                          />
                      </td>

                  </tr>

              </Fragment>
          ))}


        </tbody>
      </table>

      {/*<div className={styles.showButton} style={{ margin: "20px auto" }}>*/}
      {/*  <button className={styles.collapseButton} onClick={handleShowHideList}>*/}
      {/*    /!*{hideList ? `Show All ${sortedData.length} Cities` : "Collapse list"}*!/*/}
      {/*    {hideList ? `SHOW MORE COUNTRIES` : "Collapse list"}*/}
      {/*  </button>*/}
      {/*</div>*/}

      <div className="footer-note mt-5">
        <ul>
          <li>Figures for December 2023.</li>
          <li>Only includes HNWIs living in each country (residents).</li>
          <li>HNWI figures rounded to nearest 100.</li>
          <li>‘Millionaires’ or ‘HNWIs’ refer to individuals with liquid investable wealth of USD 1 million or more.</li>
        </ul>
        <p>Source: New World Wealth</p>
      </div>
    </>
  )
}

export default WealthRemains;