const outflowTop10 = [
    {
        "country": "Nigeria",
        "outflowVal": -300,
        "notes": "Religious tensions, high crime rates, and currency weakness all weigh heavily on Nigeria. The number of HNWIs living in the country is down by a dramatic 45% over the past decade, making it the worst performing market in Africa over this period. Popular destinations for millionaires leaving Nigeria include the UAE, the UK, and South Africa, as well as several countries in Europe.",
        "url": ""
    },
    {
        "country": "Vietnam",
        "outflowVal": -300,
        "notes": "Vietnam’s inclusion on the top outflows list is somewhat of a contradiction as it is currently the fastest growing wealth hub in the world, with millionaire growth of +98% recorded over the past decade. However, the large outflow in 2024 may be a negative sign for the future.",
        "url": ""
    },
    {
        "country": "Taiwan (Chinese Taipei)",
        "outflowVal": -400,
        "notes": "Tensions between Taiwan (Chinese Taipei) and mainland China may be a factor in the recent uptick in millionaire flows out of Taiwan. However, Taiwan’s dominance of the global semiconductor market makes it a highly resilient economy, with over 60,000 resident HNWIs.",
        "url": ""
    },
    {
        "country": "South Africa",
        "outflowVal": -600,
        "notes": "2024 is an election year in South Africa and there are concerns that the incoming government may clamp down on HNWIs via the introduction of new wealth taxes and the phasing out of the private healthcare system in favor of a government-run National Health Insurance model (NHI). High crime rates and power cuts also remain a concern. South Africa traditionally loses 300 to 400 HNWIs to migration annually so the 2024 figures represent a new record outflow.",
        "url": ""
    },
    {
        "country": "Brazil",
        "outflowVal": -800,
        "notes": "Despite a recent change of government and an uptick in economic growth, Brazil continues to lose large numbers of millionaires to emigration. Popular destinations traditionally include the USA and Portugal.",
        "url": ""
    },
    {
        "country": "Russian Federation",
        "outflowVal": -1000,
        "notes": "Ongoing sanctions and exchange controls continue to make it difficult for HNWIs to operate from Russia. Millionaire outflows from the country peaked in 2022 when over 8,500 HNWIs left.",
        "url": ""
    },
    {
        "country": "South Korea",
        "outflowVal": -1200,
        "notes": "The number of millionaires leaving South Korea has been accelerating over the past few years and a record number are expected to depart in 2024. Popular destinations include the USA, Australia, and Canada.",
        "url": ""
    },
    {
        "country": "India",
        "outflowVal": -4300,
        "notes": "India continues to lose large numbers of millionaires, especially to the UAE. However, in our view these outflows are not particularly concerning as India continues to produce far more new HNWIs than it loses to emigration. Furthermore, the bulk of the millionaires who leave India tend to retain business interests and second homes in the country, which is a positive sign.",
        "url": ""
    },
    {
        "country": "UK",
        "outflowVal": -9500,
        "notes": "This represents a new record outflow for the UK, with London expected to be especially hard hit. The top destinations for millionaires leaving the UK include the likes of Paris, Dubai, Amsterdam, Monaco, Geneva, Sydney, and Singapore, as well as retirement hotspots such as Florida, the Algarve, Malta, and the Italian Riviera.",
        "url": "https://www.henleyglobal.com/residence-investment/united-kingdom"
    },
    {
        "country": "China",
        "outflowVal": -15200,
        "notes": "This represents a new record outflow for China. General wealth growth in the country has been slowing over the past few years, which means that these outflows could be more damaging than usual. China’s economy grew strongly from 2000 to 2017, but wealth and millionaire growth in the country has been slow since then. Popular destinations for millionaires leaving China traditionally include Singapore, the USA, and Canada, with Japan a new destination to watch.",
        "url": ""
    }
];


export {outflowTop10};