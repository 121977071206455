import axios from "axios";
import {useState, useEffect} from "react";
import { PRIVATE_WEALTH_DATA, WEALTH_MIGRATION_DATA} from "../config/constants";

const useData = () => {
    const [privateWealthData, setPrivateWealthData] = useState(null);
    const [wealthMigrationData, setWealthMigrationData] = useState(null);

    const fetchData = async () => {
        const dataWealth  = await axios.get(PRIVATE_WEALTH_DATA);
        const dataMigration = await axios.get(WEALTH_MIGRATION_DATA);

        setPrivateWealthData(dataWealth.data);
        setWealthMigrationData(dataMigration.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {
        privateWealthData,
        wealthMigrationData
    }
};


export default useData;
