const top15Overview = [
    {
        "rank": 1,
        "country": "USA",
        "millionaires": 5492400,
        "centiMillionaires": 9850,
        "billionaires": 788,
        "centiGrowth": 62
    },
    {
        "rank": 2,
        "country": "China",
        "millionaires": 862400,
        "centiMillionaires": 2352,
        "billionaires": 305,
        "centiGrowth": 92
    },
    {
        "rank": 3,
        "country": "Germany",
        "millionaires": 806100,
        "centiMillionaires": 1075,
        "billionaires": 82,
        "centiGrowth": 15
    },
    {
        "rank": 4,
        "country": "Japan",
        "millionaires": 754800,
        "centiMillionaires": 748,
        "billionaires": 39,
        "centiGrowth": -6
    },
    {
        "rank": 5,
        "country": "UK",
        "millionaires": 602500,
        "centiMillionaires": 830,
        "billionaires": 75,
        "centiGrowth": -8
    },
    {
        "rank": 6,
        "country": "France",
        "millionaires": 506000,
        "centiMillionaires": 605,
        "billionaires": 55,
        "centiGrowth": 14
    },
    {
        "rank": 7,
        "country": "Switzerland",
        "millionaires": 427700,
        "centiMillionaires": 730,
        "billionaires": 40,
        "centiGrowth": 38
    },
    {
        "rank": 8,
        "country": "Australia",
        "millionaires": 383300,
        "centiMillionaires": 463,
        "billionaires": 48,
        "centiGrowth": 35
    },
    {
        "rank": 9,
        "country": "Canada",
        "millionaires": 371200,
        "centiMillionaires": 495,
        "billionaires": 52,
        "centiGrowth": 29
    },
    {
        "rank": 10,
        "country": "India",
        "millionaires": 326400,
        "centiMillionaires": 1044,
        "billionaires": 120,
        "centiGrowth": 85
    },
    {
        "rank": 11,
        "country": "Italy",
        "millionaires": 289300,
        "centiMillionaires": 418,
        "billionaires": 38,
        "centiGrowth": 16
    },
    {
        "rank": 12,
        "country": "Singapore",
        "millionaires": 244800,
        "centiMillionaires": 336,
        "billionaires": 30,
        "centiGrowth": 64
    },
    {
        "rank": 13,
        "country": "Hong Kong (SAR China)",
        "millionaires": 143400,
        "centiMillionaires": 320,
        "billionaires": 35,
        "centiGrowth": -4
    },
    {
        "rank": 14,
        "country": "UAE",
        "millionaires": 116500,
        "centiMillionaires": 308,
        "billionaires": 20,
        "centiGrowth": 77
    },
    {
        "rank": 15,
        "country": "South Korea",
        "millionaires": 109600,
        "centiMillionaires": 233,
        "billionaires": 24,
        "centiGrowth": 28
    }
];

export {top15Overview};