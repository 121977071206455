const inflowTop10 = [
    {
        "country": "UAE",
        "inflowVal": 6700,
        "notes": "The UAE consistently attracts large numbers of millionaires from India, the Middle East, Russia, and Africa. In 2024, large inflows are also expected to come from the UK and Europe, which shows its growing appeal.",
        "url": "https://www.henleyglobal.com/residence-investment/united-arab-emirates"
    },
    {
        "country": "USA",
        "inflowVal": 3800,
        "notes": "America’s growing dominance of the global tech sector is a key factor in helping it to attract millionaires, along with the ongoing rise of Florida as one of the top retirement destinations for the world’s wealthy.",
        "url": "https://www.henleyglobal.com/residence-investment/united-states"
    },
    {
        "country": "Singapore",
        "inflowVal": 3500,
        "notes": "Widely regarded as the most business-friendly city on earth, Singapore consistently ranks among the top destinations for migrating millionaires. It is also the world’s fastest growing family office hub, aided by the absence of capital gains tax in the city-state.",
        "url": "https://www.henleyglobal.com/residence-investment/singapore"
    },
    {
        "country": "Canada",
        "inflowVal": 3200,
        "notes": "Canada is a perennially popular destination for migrating millionaires, especially from Asia and Europe. Toronto and Vancouver are the key destination cities, while Calgary is one to watch for the future.",
        "url": "https://www.henleyglobal.com/residence-investment/canada"
    },
    {
        "country": "Australia",
        "inflowVal": 2500,
        "notes": "It should be noted that millionaire inflows into Australia for 2024 are well below the peak levels recorded in the 2010s when the country consistently attracted over +5,000 HNWIs per annum. Sydney and Melbourne are traditionally the main destination cities.",
        "url": "https://www.henleyglobal.com/residence-investment/australia"
    },
    {
        "country": "Italy",
        "inflowVal": 2200,
        "notes": "Many of Italy’s inbound millionaires are heading to the affluent regions of Liguria (Italian Riviera) and Lombardy. Estate duty in Italy is a relatively modest 4%, which may contribute to its recent surge in attracting millionaires, especially from high-tax countries such as the UK. Furthermore, Italy’s economy is performing well compared to most other European countries.",
        "url": "https://www.henleyglobal.com/residence-investment/italy"
    },
    {
        "country": "Switzerland",
        "inflowVal": 1500,
        "notes": "Despite a dip in popularity over the past few years, Switzerland remains one of the world’s top destinations for migrating millionaires. Geneva, Lugano, and Zug are all particularly powerful millionaire magnets.",
        "url": "https://www.henleyglobal.com/residence-investment/switzerland"
    },
    {
        "country": "Greece",
        "inflowVal": 1200,
        "notes": "Despite a tough start to the decade following the Greek financial crisis, Greece has rebounded strongly, with real estate prices and millionaire numbers in the country rising significantly over the past few years in particular. Greater Athens (Attica), Mykonos, and Crete are all hotspots among wealthy migrants. ",
        "url": "https://www.henleyglobal.com/residence-investment/greece"
    },
    {
        "country": "Portugal",
        "inflowVal": 800,
        "notes": "Portugal has seen a large influx of wealthy residents over the past decade, especially from mainland Europe, the UK, Brazil, South Africa, China, Türkiye, and the USA. Despite recent changes to its popular Golden Residence Permit Program, we expect Portugal to continue to attract large numbers of millionaires over the coming year.",
        "url": "https://www.henleyglobal.com/residence-investment/portugal"
    },
    {
        "country": "Japan",
        "inflowVal": 400,
        "notes": "There is a new trend of Chinese millionaires moving to Tokyo that started post-Covid and is beginning to accelerate. The lifestyle in Japan is very appealing, with beautiful public gardens and golf courses, plus it’s ranked among the safest countries on earth according to the Global Peace Index. Furthermore, despite a tough past decade, Japan’s overall economy is showing signs of recovery, with the Nikkei Stock Average up by +28% in 2023.",
        "url": ""
    }
];


export {inflowTop10};