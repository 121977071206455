import React from 'react';
import styles from "./detail.module.scss";
import { formatNumberWithDot, imageLink } from '../../../hooks/utils';
import CountryFlag from "../countryFlag/country-flag";

const countryLinks = [{'country': 'Monaco', 'text': 'centi-millionaires', 'link': 'https://www.henleyglobal.com/publications/centi-millionaire-report-2023' },
                                                {'country': 'Nigeria', 'text': 'worst performing market in Africa', 'link': 'https://www.henleyglobal.com/publications/africa-wealth-report-2024/africas-wealthiest-countries'}]
const WealthFlowDetail = ({item, onClose}) =>{
    const value = (item.inflowVal) ? item.inflowVal : item.outflowVal;
    const countryName = item.country;
    const hwniVal = value > 0 ? "+" + formatNumberWithDot(value) : "-" + formatNumberWithDot(Math.abs(value));

    // const notes = item.notes ? item.notes : 'notes placement ';
    const urlText = `Apply for residence in ${countryName}`;
    // const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a></p>) : (<p className="mt-auto mb-0">&nbsp;</p>);
    const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a></p>) : (null);

    // const circleMark = item.category === "inflow" ? (<span className="circle circle-inflow"></span>) : (<span className="circle circle-outflow"></span>);

    const targetLinksItem = countryLinks.filter((i) => i.country === item.country)[0];
    const splited =  (targetLinksItem) ? (item.notes).split(targetLinksItem.text) : null;
    const newNotes = (targetLinksItem) ?
        (<p className="mt-auto">{splited[0]} <a href={targetLinksItem.link} target="_blank" rel="noreferrer" className="fw-bold text-underline">{targetLinksItem.text}</a> {splited[1]}</p>)
        : (<p>{item.notes}</p>);


    return(
        <div
            className={`container shadow ${styles.wealthpopup} ${styles.popupWealthFlowCard} ${styles.card} p-0 bg-light`
            // style={{top: '-50px'}}
        }
        >
            <button
                className={`${styles.closeButton} ${styles.shadowBackground}`}
                onClick={onClose}
                style={{width: '32px', height: '32px'}}
            >
                {/*close*/}
                <svg width="10px" height="9px" viewBox="0 0 10 9" style={{overflow: 'visible'}}>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-702.000000, -7.000000)" stroke="currentColor">
                            <g transform="translate(700.692388, 4.692388)">
                                <g transform="translate(6.500000, 6.500000) rotate(45.000000) translate(-6.500000, -6.500000) ">
                                    <line x1="6.5" y1="1" x2="6.5" y2="12"></line>
                                    <line x1="6.5" y1="1" x2="6.5" y2="11.5"
                                          transform="translate(6.500000, 6.250000) rotate(-90.000000) translate(-6.500000, -6.250000) "></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <div className="col position-static">
                <div className="row">

                    <div className={styles.countryImageWrapper}>
                        <img src={imageLink(countryName)} alt={countryName}/>
                    </div>
                </div>
                <div className={`row d-flex flex-column justify-content-between p-4`}>
                    <div className='row mb-4'>
                        <div className={`col-8 mt-auto pr-0 ${styles.col8p5} `}>
                            <h3 className={`mb-0 ${styles.countryName} `}>
                                <CountryFlag country={item.country}/>
                            </h3>
                        </div>

                        <div className={`col-4 d-inline-block mt-auto pl-0`}>
                            {/*<div className="col-4 d-flex flex-column justify-content-between">*/}
                            <h3 className={` text-right text-nowrap align-bottom ${styles.hwni}`}>{hwniVal} HNWIs</h3>
                            {/*</div>*/}

                        </div>
                    </div>
                    {newNotes}

                    {url}
                </div>

            </div>

        </div>

    )
}

export default WealthFlowDetail;