import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from "./App";
import qs from "qs";
import CountryInflow from "./components/country-inflow/country-inflow";
import CountryOutflow from "./components/country-outflow/country-outflow";
import Watchlist from "./components/watchlist/watchlist";
import Top15 from "./components/top-15/top-15";
import WealthRemains from "./components/wealth-remains/wealth-remains";
import MigrationGraph from "./components/migration/migration";
import WatchlistInflow from "./components/watchlist-inflow/watchlist-inflow";
function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

// function getEls(embedSelector) {
//     if (typeof embedSelector === "string") {
//         const els = document.querySelectorAll(embedSelector);
//         // if (!els) throw new Error(`No divs matching selector "${embedSelector}"`);
//         if (!els) return;
//         return els;
//     } else {
//         return embedSelector;
//     }
// }

function getUrlParams() {
    const params = qs.parse(window.location.search.slice(1));

    return {
        type: params.embed,
        city: params.city,
    };
}

window.addEventListener("DOMContentLoaded", () => {
    const params = getUrlParams();
    
    if (params) {
        // console.log("Embed params");
        // console.table(params);

        // [...getEls("[data-citycountry-embed]")].map((e) => {
        //     ReactDOM.render(<CityEmbed {...params} citySlug={e.getAttribute("data-citycountry-embed")}/>, e);
        // })


        // if (getEl("[data-app-embed]")) {
        //     const container = getEl("[data-app-embed]");
        //     const root = createRoot(container); // createRoot(container!) if you use TypeScript
        //     // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
        //     root.render(<App {...params} />);
        // }

        if (getEl("[data-country-inflows-embed]")) {
            const container = getEl("[data-country-inflows-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
            root.render(<CountryInflow {...params} />);
        }

        if (getEl("[data-country-outflows-embed]")) {
            const container = getEl("[data-country-outflows-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
            root.render(<CountryOutflow {...params} />);
        }

        if (getEl("[data-watchlist-embed]")) {
            const container = getEl("[data-watchlist-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
            root.render(<WatchlistInflow {...params} />);
        }


        if (getEl("[data-mflows-embed]")) {
            const container = getEl("[data-mflows-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            root.render(<MigrationGraph {...params} />);
        }

        if (getEl("[data-top15-embed]")) {
            const container = getEl("[data-top15-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            root.render(<Top15 {...params} />);
        }

        if (getEl("[data-remains-embed]")) {
            const container = getEl("[data-remains-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            root.render(<WealthRemains {...params} />);
        }

    }
});
