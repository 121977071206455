import React, { useState, useEffect } from 'react';
import styles from  './progress-bar-outflow.module.scss'; // import CSS file with animation styles

const  ProgressBarOutflow = ({percentValue, labelText, type}) => {

  const [percent, setPercent] = useState(0);
  type = 'left';

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    // You need to wrap it to trigger the animation
    requestAnimationFrame(() => {
      // Set a new array of percentage widths based on the props
      setPercent(Math.abs(percentValue));
    });

  }, [percentValue]);

  // const minusProgressBar = (type) =>{
  //   if(type === 'both'){
  //     return(
  //       <>
  //         <div className={`${styles.progressBar} ${styles.directionLeft}`}>
  //           {percentValue < 0 && (
  //             <div className={styles.progress} style={{ width: `${percent}%` }}></div>
  //           )}
  //         </div>
  //       </>
  //     )
  //   }
  // }

  return (
    <div className={styles.progressBarContainer}>
      {/* {minusProgressBar(type)} */}
      <div
        className={`${styles.progressBar} ${type === "left" ? styles.directionLeft: ""}`}
      >
        {percentValue !== 0 &&(
          <div
              className={styles.progress} 
              style={{ width: `${percent}%` }}
          >  
          </div>
        )}
      </div>
      {labelText && (
          <span className={styles.labelText}>{labelText}</span>
      )}
     
    </div>
  );
}

export default ProgressBarOutflow;
